import { convertLegacyProps } from "antd/lib/button/button";
import React, { lazy ,Suspense} from "react";
import routers from  '../router/router';
let found = false;
const Utils={
    getSearchObj(searchStr){
        let qs=searchStr || window.location.search || window.location.hash;
            qs=decodeURIComponent(qs.substr(qs.indexOf('?')+1));
        let args = {};
        let items = qs.length>0 ? qs.split('&'):[];
        let item = null,name = null,value = null,len = items.length;
        for(let i = 0;i < len; i++){
            item = items[i].split('=');
            name = decodeURIComponent(item[0]);
            value = decodeURIComponent(item[1]);
            if(name.length){
                args[name] = value;
            }
        }
        return args;
    },
    getRunningENV(queryEnvKey,envConfig,hosts) {
        let env;
        let hostEnvKey;
        if(queryEnvKey && envConfig[queryEnvKey]){
            hostEnvKey = queryEnvKey;
        }else{
            let port=window.location.port;
            let host=window.location.hostname;
            if(hosts){
                if(hosts[host]){
                    hostEnvKey = hosts[host];
                }
            }
            if(!hostEnvKey){
                if(port){
                    hostEnvKey = 'dev';
                }else if(host.indexOf('dev.')===0){
                    hostEnvKey = 'dev';
                }else if(host.indexOf('test.')===0){
                    hostEnvKey = 'test';
                }else if(host.indexOf('pre.')===0){
                    hostEnvKey = 'pre';
                }else{
                    hostEnvKey = 'prod'; 
                }
            }
        }
        env = envConfig[hostEnvKey];
        env.envKey = hostEnvKey;
        return env;
    },
    hashCode(str){
        let hash = 0;
        if(typeof str==='string' && str) {
            for (let i = 0; i < str.length; i++) {
                hash = hash * 31 + str.charCodeAt(i);
                hash = this.intValue(hash);
            }
        }
        return hash;
    },
    manageWebMenu(data){
        let menuList=data.filter((item)=>{return item.WebMenuParentID==null});
        data.forEach((item)=>{
            item.key=item.WebMenuID;
            item.title=item.WebMenuName;
            if(item.WebMenuParentID!=null){
                let currentList=menuList[menuList.findIndex((list)=>{return list.WebMenuID===item.WebMenuParentID})];
                currentList.children=currentList.children ? currentList.children.concat(item):currentList.children=[].concat(item);
            }
        })
        return menuList
    },
    newManageWebMenu(data){
        let menuList=data.filter((item)=>{return item.WebMenuParentID==null});
        data.forEach((item)=>{
            if(item.WebMenuParentID!=null){
                let currentList=menuList[menuList.findIndex((list)=>{return list.WebMenuID===item.WebMenuParentID})];
                currentList.children=currentList.children ? currentList.children.concat(item):currentList.children=[].concat(item);
            }
        })
        return menuList
    },
    manageUser(data){
        let userList=data.filter((item)=>{return item.UserParentID==null});
        data.forEach((item)=>{
            if(item.UserParentID!=null){
                let currentList=userList[userList.findIndex((list)=>{return list.UserID===item.UserParentID})];
                currentList.childs = currentList.childs ? currentList.childs.concat(item):currentList.childs=[].concat(item);
            }
        })
        return userList;
    },
    searchValue(value){
        if(value){
            return value.charAt(value.length-1)==" " ? value.substr(0,value.length-1) : value;
        }
    },
    disposeNum(value,ExchangeName,Symbol,type){
        let TradingRule=window.ctx?.TradingRule
        let returns={
            value:value,
            color:"grey"
        }
        let digit=6;
        if(ExchangeName && Symbol && TradingRule && type){ 
            let findIndex=TradingRule.findIndex((list)=>{return list.ExchangeName===ExchangeName && list.Symbol===Symbol})
            if(findIndex!=-1 && TradingRule[findIndex]){
                if(type==="Price" && TradingRule[findIndex].MinPriceIncrement>=0){
                    digit=TradingRule[findIndex].MinPriceIncrement
                }
                if(type==="Amount" && TradingRule[findIndex].MinBaseAmountIncrement>=0){
                    digit=TradingRule[findIndex].MinBaseAmountIncrement
                }
            }
        }
        switch(typeof(value)){
            case 'number' :
                let number= Number.isInteger(value) ? value : parseFloat(value).toFixed(parseInt(digit));
                if(number>0){
                    returns.color='green';
                    returns.value=number;
                };
                if(number==0){
                    returns.value=number;
                    returns.color="green";
                };
                if(number<0){
                    returns.color='red';
                    returns.value=number;
                };  
                return returns;
            case 'object' : 
                returns.value="NULL";
                return returns;
            case 'string' :
                return returns;
        }
    },
    scientificNotationToString(param) {
        let returns={
            value:param,
            color:"green"
        }
        let digit=8;
        if (isNaN(param)) {
            return param
        }
        param = '' + param
        param = parseFloat(param)
        let eformat = param.toExponential() // 转换为标准的科学计数法形式（字符串）
        let tmpArray = eformat.match(/\d(?:\.(\d*))?e([+-]\d+)/) // 分离出小数值和指数值
        let number = param.toFixed(Math.max(0, (tmpArray[1] || '').length - tmpArray[2]))  
        returns.value=parseFloat(number).toFixed(digit) 
        return returns 
    },
    
    sorterNum(value){
        return (a, b) => a[value] - b[value];
    },
    sorterLetter(value){
        return (a, b) => a[value].localeCompare(b[value]);
    },
    sortTimestamp(list,type){
        list.sort(function(a, b) {
            return b[type] > a[type] ? -1 : 1
        })
    },
    nFormatter(num, digits) {
        const si = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "K" },
            { value: 1E6, symbol: "M" },
            { value: 1E9, symbol: "G" },
            { value: 1E12, symbol: "T" },
            { value: 1E15, symbol: "P" },
            { value: 1E18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    },
    //随机数
    randomNum(minNum,maxNum){ 
        switch(arguments.length){ 
            case 1: 
                return parseInt(Math.random()*minNum+1,10); 
            break; 
            case 2: 
                return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10); 
            break; 
                default: 
                    return 0; 
                break; 
        } 
    },
    /**
     * 扁平化路由数组
     */
    flatNavigationRoutes:["/Monitor/Products"].map((path) => routers.find((r) => r.path === path)),
    /**
     * 修改扁平化路由数组
     */
    updateFlatNavigationRoutes(trees,hasComponent = false) {
        this.flatNavigationRoutes = this.getFlatNavigationRoutes(trees, hasComponent);
    },
    /**
     * 获取扁平化路由 如果第二个参数传入true，则返回的路由数组仅包含提供了component的路由
     */
    getFlatNavigationRoutes(trees,hasComponent = false) {
        const routes = [];
        trees.forEach((item) => {
            const { children, ...rest } = item;
            if (!hasComponent || !!rest.element) routes.push(rest);
            if (children) {
                routes.push(...this.getFlatNavigationRoutes(children));
            }
        });
        return routes;
    },
    /**
     * 路由转化成菜单列表
     */
    navigationRoutesToMenuItems(routes){
        return (
            /** 过滤掉需要隐藏不显示在菜单列表中的路由 */
            routes && routes?.filter((item) => !item.hideInMenu).map((item) => {
                const { lable, path, children, element, ...rest } = item;
                return {
                    label:lable,
                    key: path,
                    children: this.navigationRoutesToMenuItems(children),
                    ...rest,
                };
            })
        );
    },
    getNavigationParentRoutes(targetPath,routes,parentKeys=[]) {
        /**
         * 一开始重置
         */
        if (found) found = false;
        for (let i = 0; i < routes.length; i++) {
            /**
             * 找到后退出
             */
            if (found) break;
            const item = routes[i];
            parentKeys.push(item.path);
            const result = this.checkNavigationPath(targetPath, item);
            if (Array.isArray(result)) {
                /**
                 * 有孩子路由时优先查孩子路由
                 */
                this.getNavigationParentRoutes(targetPath, result, parentKeys);
            } else if (result) {
                found = true;
            }
            /**
             * 该路由找不到就从数组移除
             */
            if (!found) parentKeys.pop();
        }
        return parentKeys;
    },
    checkNavigationPath(path,route) {
        return route.children ?? route.path === path;
    },
    /* 获取路由信息
    */
    getNavigationRouteInfo(key,routers=[]) {
        return routers.find((item) => item.path === key);
    },
    //数组根据属性去重
    uniqueByProperties(arr, properties) {
      return arr.reduce((acc, current) => {
        const key = properties.map(prop => current[prop]).join('-');
        if (!acc.some(item => properties.map(prop => item[prop]).join('-') === key)) {
          acc.push(current);
        }
        return acc;
      }, []);
    }
}
export default Utils