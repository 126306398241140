import React from "react";
let conf={
    apiInstance: {
        hosts: {
            'localhost': 'dev'
        },
        app:{},
        env:{
            dev:{
                apiHost:'https://octonauts.net/AsymmetriesAssetManagement/'
                // apiHost:'http://54.178.63.160/'
                // apiHost:'https://ziguan_api.asymmetriesformular.com/AsymmetriesAssetManagement/',
            },
            prod:{
                apiHost:'https://octonauts.net/AsymmetriesAssetManagement/'
                // apiHost:'http://54.178.63.160/'
                // apiHost:'https://ziguan_api.asymmetriesformular.com/AsymmetriesAssetManagement/',
            },
        },
        api:{
            login:{method:'post',url:'API/Login.ashx'},
            getUserInfo:{method:'post',url:'API/getUserInfo.ashx'},
            setMyPassword:{method:'post',url:'API/setMyPassword.ashx'},

            getAllWebMenu:{method:'post',url:'API/getAllWebMenu.ashx'},
            setWebMenu:{method:'post',url:'API/setWebMenu.ashx'},
            getMyWebMenu:{method:'post',url:'API/getMyWebMenu.ashx'},
            getUserWebMenu:{method:'post',url:'API/getUserWebMenu.ashx'},
            setUserWebMenu:{method:'post',url:'API/setUserWebMenu.ashx'},
           
            search:{method:'post',url:'API/search.ashx'},
            getTradingRule:{method:'post',url:'API/getTradingRule.ashx'},
            getMyPosition:{method:'post',url:'API/getMyPosition.ashx'},
            getMyAccount:{method:'post',url:'API/getMyAccount.ashx'},
            setMyAccount:{method:'post',url:'API/setMyAccount.ashx'},
            getMyActiveOrder:{method:'post',url:'API/getMyActiveOrder.ashx'},

            getMyBalance:{method:'post',url:'API/getMyBalance.ashx'},

            getMyProduct:{method:'post',url:'API/getMyProduct.ashx'},
            setMyProduct:{method:'post',url:'API/setMyProduct.ashx'},
            getProductBasePosition:{method:'post',url:'API/getProductBasePosition.ashx'},
            getProductCurrentBalance:{method:'post',url:'API/getProductCurrentBalance.ashx'},
            getProductCurrentBalanceNOW:{method:'post',url:'API/getProductCurrentBalanceNOW.ashx'},
            getProductExposure:{method:'post',url:'API/getProductExposure.ashx'},
            getPositionByProduct:{method:'post',url:'API/getPositionByProduct.ashx'},

            getFundingRate:{method:'post',url:'API/getFundingRate.ashx'},
            setMyFundingRate:{method:'post',url:'API/setMyFundingRate.ashx'},
            getMyFundingRate:{method:'post',url:'API/getMyFundingRate.ashx'},

            getPrice:{method:'post',url:'API/getPrice.ashx'},

            getDepartment:{method:'post',url:'API/getDepartment.ashx'},
            setNewUser:{method:'post',url:'API/setNewUser.ashx'},
            getAllUsers:{method:'post',url:'API/getAllUsers.ashx'},
            setUserInfo:{method:'post',url:'API/setUserInfo.ashx'},
            getAllProduct:{method:'post',url:'API/getAllProduct.ashx'},
            getUserProduct:{method:'post',url:'API/getUserProduct.ashx'},
            setUserProduct:{method:'post',url:'API/setUserProduct.ashx'},
            getAllAccount:{method:'post',url:'API/getAllAccount.ashx'},
            getUserAccount:{method:'post',url:'API/getUserAccount.ashx'},
            setUserAccount:{method:'post',url:'API/setUserAccount.ashx'},
            
            getAllCurrency:{method:'post',url:'API/getAllCurrency.ashx'},
            setProduct:{method:'post',url:'API/setProduct.ashx'},
            setProductInfo:{method:'post',url:'API/setProductInfo.ashx'},
            getProductAccount:{method:'post',url:'API/getProductAccount.ashx'},
            getProductUser:{method:'post',url:'API/getProductUser.ashx'},

            getAllExchangeName:{method:'post',url:'API/getAllExchangeName.ashx'},
            setNewAccount:{method:'post',url:'API/setNewAccount.ashx'},
            setProductAccount:{method:'post',url:'API/setProductAccount.ashx'},
            setTransfer:{method:'post',url:'API/setTransfer.ashx'},
            getTransfer:{method:'post',url:'API/getTransfer.ashx'},
            setProductMemo:{method:'post',url:'API/setProductMemo.ashx'},
            getProductMemo:{method:'post',url:'API/getProductMemo.ashx'},

            getCustomer:{method:'post',url:'API/getCustomer.ashx'},
            setChangeBasePosition:{method:'post',url:'API/setChangeBasePosition.ashx'},
            getProductBasePositionTransaction:{method:'post',url:'API/getProductBasePositionTransaction.ashx'},
            getProductNetValueHistory:{method:'post',url:'API/getProductNetValueHistory.ashx'},
            setProductBasePosition:{method:'post',url:'API/setProductBasePosition.ashx'},

            setUserActionLog:{method:'post',url:'API/setUserActionLog.ashx'},
            getUserActionLog:{method:'post',url:'API/getUserActionLog.ashx'},

            getRiskControl:{method:'post',url:'API/getRiskControl.ashx'},
            setRiskControlNew:{method:'post',url:'API/setRiskControlNew.ashx'},
            setRiskControlUpdate:{method:'post',url:'API/setRiskControlUpdate.ashx'},
            setRiskControlDelete:{method:'post',url:'API/setRiskControlDelete.ashx'},
            getAlarm:{method:'post',url:'API/getAlarm.ashx'},

            setProductPortfolio:{method:'post',url:'API/setProductPortfolio.ashx'},
            getProductPortfolio:{method:'post',url:'API/getProductPortfolio.ashx'},
            getRiskControlParameter:{method:'post',url:'API/getRiskControlParameter.ashx'},
            getProductCurrentSituation:{method:'post',url:'API/getProductCurrentSituation.ashx'},
            getProductCurrentSituationNOW:{method:'post',url:'API/getProductCurrentSituationNOW.ashx'},
            getProductChangeHistory:{method:'post',url:'API/getProductChangeHistory.ashx'},
            setDeleteProductNetValueHistory:{method:'post',url:'API/setDeleteProductNetValueHistory.ashx'},
            getProductCurrentBalanceHistory:{method:'post',url:'API/getProductCurrentBalanceHistory.ashx'},
            getProductBasePositionHistory:{method:'post',url:'API/getProductBasePositionHistory.ashx'},
            getProductExposureHistory:{method:'post',url:'API/getProductExposureHistory.ashx'},
            getProductPositionHistory:{method:'post',url:'API/getProductPositionHistory.ashx'},

            //Defi
            getUniswapV3:{method:'post',url:'API/getUniswapV3.ashx'},
            getPortfolio:{method:'post',url:'API/getPortfolio.ashx'},
            getAllPortfolio:{method:'post',url:'API/getAllPortfolio.ashx'},
            setPortfolioInfo:{method:'post',url:'API/setPortfolioInfo.ashx'},
            getPortfolioBin:{method:'post',url:'API/getPortfolioBin.ashx'},
            getPortfolioWallet:{method:'post',url:'API/getPortfolioWallet.ashx'},
            getPortfolioBasePosition:{method:'post',url:'API/getPortfolioBasePosition.ashx'},
            setPortfolioBasePosition:{method:'post',url:'API/setPortfolioBasePosition.ashx'},
            getPortfolioCurrentBalance:{method:'post',url:'API/getPortfolioCurrentBalance.ashx'},
            getPortfolioExposure:{method:'post',url:'API/getPortfolioExposure.ashx'},
            setUserPortfolio:{method:'post',url:'API/setUserPortfolio.ashx'},
            getUserPortfolio:{method:'post',url:'API/getUserPortfolio.ashx'},
            getAllWallet:{method:'post',url:'API/getAllWallet.ashx'},
            setPortfolioWallet:{method:'post',url:'API/setPortfolioWallet.ashx'},
            setPortfolio:{method:'post',url:'API/setPortfolio.ashx'},

            getFactorBeta:{method:'post',url:'API/getFactorBeta.ashx'},

            setSchedule:{method:'post',url:'API/setSchedule.ashx'},
            getSchedule:{method:'post',url:'API/getSchedule.ashx'},

            getInjectiveNode:{method:'post',url:'API/getInjectiveNode.ashx'},
            getInjectiveMachine:{method:'post',url:'API/getInjectiveMachine.ashx'},

            getTradeLogsStrategy:{method:'post',url:'API/getTradeLogsStrategy.ashx'},
            getTradeLogs:{method:'post',url:'API/getTradeLogs.ashx'},
            getBacktestLogsStrategy:{method:'post',url:'API/getBacktestLogsStrategy.ashx'},
            getBacktestLogs:{method:'post',url:'API/getBacktestLogs.ashx'},

            Alert:{method:'get',url:'API/Alert.ashx'},
            getOverview:{method:'get',url:'API/getOverview.ashx'},
            getOverviewAccount:{method:'get',url:'API/getOverviewAccount.ashx'}

        }
    }   
};
export default conf;
