class EventDispatcher {
    constructor(){
        let self=this;
        self._listeners = {};
        self.addEventListener = function(type, listener) {
            if('function' !== typeof listener){
                return;
            }
            if(typeof type==='string' && type){
                let listeners = self._listeners[type];
                if (!listeners){
                    listeners = self._listeners[type] = [];
                }
                let flag=false;
                listeners.forEach((lis)=>{
                    if(lis===listener){
                        flag=true;
                    }
                })
                !flag && listeners.push(listener);
            }else if('object'=== typeof type && type.length){
                type.forEach((t)=>{
                    self.addEventListener(t,listener);
                })
            }
        };
        self.removeEventListener = function(type, listener) {
            let listeners = self._listeners[type];
            if (!listeners || !listeners.length){
                return;
            }else{
                if('function' === typeof listener){
                    self._listeners[type] = listeners.filter((lit)=>{
                        return lit!==listener ? true : false
                    })
                }else{
                    Reflect.deleteProperty( self._listeners,type);
                }
            }
        }
        self.clearEventListener = function(type) {
            if(type) {
                Reflect.has(self._listeners,type) && Reflect.deleteProperty(self._listeners,type);
            }else {
                self._listeners = {};
            }
        }
        self.dispatchEvent = function(type,data,param) {
            let listeners = self._listeners[type];
            if (!listeners || !listeners.length) return;
            let event={
                type:type,
                data:typeof data === 'function'? data():data,
                param:param
            }
            for (let i in listeners) {
                listeners[i](event);
            }
        }
    }
}
export default EventDispatcher
